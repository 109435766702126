import logo from './logo.svg';
import './App.css';
import Register from "./Pages/Register";
import "./style.scss";
import Home from "./Pages/Home";
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import Login from "./Pages/Login";
import {AuthContext} from "./context/AuthContext";
import {useContext} from "react";

function App() {

    const { currentUser } = useContext (AuthContext)

    const ProtectedRoute = ( {children} ) => {
        if (!currentUser){
            return <Navigate to="/login" />;
        }
        return children
    };

  return (
      <BrowserRouter>
        <Routes>
          <Route path="/">
            {/*<Route index element= { currentUser ? <Home /> : <Login /> } />*/}
            <Route index element = {
                <ProtectedRoute>
                    <Home />
                </ProtectedRoute>
                }
                />
            <Route path="login" element = {<Login />} />
            <Route path="register" element = {<Register />} />
          </Route>
        </Routes>
      </BrowserRouter>
  );
}


export default App;
